<template>
  <div class="flex flex-col items-center lg:pt-6 pt-2">
    <div
      class="intro fixed top-0 left-0 h-100vh w-100vw z-10 pointer-events-none"
    >
      <video
        v-if="$isMobile()"
        class="object-cover w-full h-full"
        ref="introVideo"
        muted
        playsinline
        autoplay
        :src="`/assets/video/category-intro/${videoTitle}-mobile.mp4`"
      ></video>
      <video
        v-else
        class="object-cover w-full h-full"
        ref="introVideo"
        muted
        playsinline
        autoplay
        :src="`/assets/video/category-intro/${videoTitle}-desktop.mp4`"
      ></video>
    </div>

    <div
      class="flex flex-col items-center z-10 md:pb-3 pb-1 opacity-0 invisible"
      ref="categoryTitle"
    >
      <div
        class="font-condensed font-semibold uppercase text-light-gold md:text-16 text-14 tracking-wider px-4"
      >
        Category {{ currentCategory }}/12
      </div>
      <div class="headline md:text-42 text-36 -mt-1">
        {{ questionText }}
      </div>
    </div>
    <div
      ref="categoryCtr"
      class="opacity-0 invisible flex flex-col items-center max-w-8xl"
    >
      <!-- Progress bar -->
      <div class="h-1 w-100vw absolute top-0 left-0 bg-black">
        <div
          ref="progressBar"
          class="bg-light-gold h-full relative inner-progress transform scale-x-0 origin-left"
        ></div>
      </div>
      <div class="flex flex-col items-center w-full">
        <div
          class="w-full h-full relative flex justify-center items-center lg:space-x-4"
        >
          <div class="xl:w-1/12 w-24 hidden lg:block">
            <img
              @click="() => logoClick('Voting Page')"
              class="w-full cursor-pointer"
              src="/assets/images/logo-3d.png"
              alt="AEW Awards Logo"
            />
          </div>
          <div
            class="flex justify-center relative w-full 2xl:w-9/12 lg:w-8/12 mx-auto"
          >
            <div
              class="bg-white absolute md:-bottom-2 -bottom-7 md:w-auto md:bg-opacity-100 bg-opacity-25 px-2 md:border-2 border-0 border-black flex justify-center items-center z-10"
            >
              <p
                class="text-13 font-condensed font-bold uppercase tracking-wide md:text-black text-white text-opacity-75 px-2 py-0"
              >
                Pick your favorite
              </p>
            </div>
            <div
              ref="videoOuter"
              class="relative md:aspect-w-16 aspect-w-15 md:aspect-h-9 aspect-h-10 w-full md:mt-0 mt-2 origin-center video-shadow"
            >
              <div
                ref="videoInner"
                class="flex justify-center items-center absolute overflow-hidden top-0 bg-black left-0 right-0 bottom-0 z-1 origin-center"
              >
                <div
                  class="hidden absolute z-10 top-3 right-3 cursor-pointer"
                  @click="toggleMute"
                >
                  <img
                    v-if="isMuted"
                    class="h-6 w-6 opacity-50 hover:opacity-75 transition duration-300"
                    src="/assets/icons/unmute.svg"
                    alt="Mute"
                  />
                  <img
                    v-if="!isMuted"
                    class="h-6 w-6 opacity-50 hover:opacity-100 transition duration-300"
                    src="/assets/icons/mute.svg"
                    alt="Unmute"
                  />
                </div>
                <div
                  ref="categoryVideo"
                  class="category-video object-cover h-full w-full relative z-1 cursor-pointer brightness-75"
                  @click="togglePause"
                />
              </div>
            </div>
          </div>
          <div class="xl:w-1/12 w-24 hidden lg:block">
            <img
              @click="() => youtubeClick('Voting Page')"
              class="w-full cursor-pointer"
              src="/assets/images/tune-in.svg"
              alt="March 23 7pm et 4pm pt on YouTube"
            />
          </div>
        </div>
      </div>
      <div
        class="w-full 2xl:w-/12 lg:w-8/12 flex flex-wrap justify-center md:mt-4 mt-9 gap-2 px-0"
      >
        <Nominee
          v-for="(answer, idx) in answers"
          :answer="answer"
          :idx="idx"
          :key="idx"
          :selectNominee="selectNominee"
          :selected="selectedNominee === answer.eventQuestionAnsweruuid"
          :category="questionText"
          :isActive="
            selectedNominee
              ? selectedNominee === answer.eventQuestionAnsweruuid
              : true
          "
        />
      </div>
    </div>
    <div
      ref="submitBtn"
      class="submit bg-light-yellow text-black font-condensed font-black text-26 fixed bottom-0 z-10 py-2 text-center w-full cursor-pointer transform translate-y-100% hover:bg-light-gold transition duration-300 overflow-hidden"
      @click="vote"
    >
      <div ref="submitInner" class="opacity-0 relative z-1">SUBMIT VOTE</div>
      <div class="shimmer absolute w-full top-0 left-0 z-0"></div>
    </div>
  </div>
</template>

<script>
import EventService from "@/services/EventService";
import Clappr from "clappr";
import { categories } from "../categories";
import Nominee from "./Nominee.vue";
import { gsap } from "gsap";

export default {
  props: ["category", "idx", "isActive", "next", "activeCategoryIdx"],
  components: {
    Nominee,
  },
  inject: ["trackInteraction", "youtubeClick", "logoClick"],
  data() {
    return {
      selectedNominee: false,
      videoOffset: 0,
      isPaused: false,
      isMuted: true,
      initialMute: true,
    };
  },

  mounted() {
    let introVideo = this.$refs.introVideo;
    introVideo.play();
    setTimeout(() => {
      gsap.to(introVideo, {
        autoAlpha: 0,
        duration: 0.2,
        onComplete: () => {
          this.animateIn();
        },
      });
    }, 3000);

    const comp = this;
    const categoryVideo = new Clappr.Player({
      source: this.answers[0].answer.mediaAssets[0].url + `?t=2.5`,
      parent: this.$refs.categoryVideo,
      width: "100%",
      height: "100%",
      mute: true,
      poster: `/assets/images/poster/${categories[this.idx].key}.jpg`,
      controls: false,
      playback: {
        playInline: true,
      },
      events: {
        onReady() {
          this.seek(2.5);
        },
        onEnded() {
          this.seek(0);
        },
        onPause() {
          comp.$refs.categoryVideo.style.webkitFilter = "brightness(40%)";
        },
        onPlay() {
          this.unmute();
          comp.$refs.categoryVideo.style.webkitFilter = "brightness(100%)";
          comp.trackInteraction({
            interactionName: "Video Play",
            widgetType: "Video Play",
            widgetName: "Video Play",
            widgetItemName: comp.category.question.question.default,
            screenName: "Voting Page",
            screenSection: comp.category.question.question.default,
          });
        },
      },
    });

    this.categoryVideo = categoryVideo;
  },
  methods: {
    selectNominee(nominee, offset) {
      let categoryVideo = this.$refs.categoryVideo;

      if (nominee.eventQuestionAnsweruuid !== this.selectedNominee) {
        this.selectedNominee = nominee.eventQuestionAnsweruuid;
        let seekTime = categories[this.activeCategoryIdx].offsets[offset];
        this.categoryVideo.seek(seekTime);
        this.categoryVideo.play();

        if (categoryVideo.muted && this.initialMute) {
          gsap.set(this.$refs.playBtn, { autoAlpha: 0 });
          categoryVideo.muted = false;
          this.isMuted = false;
          this.initialMute = false;
        }

        gsap.to(this.$refs.submitBtn, {
          y: 0,
          duration: 0.15,
          ease: "Power3.inOut",
          onComplete: () => {
            gsap.to(".shimmer", {
              y: "-150%",
              duration: 0.7,
              ease: "Expo.inOut",
            });
          },
        });

        gsap.to(this.$refs.submitInner, {
          opacity: 1,
          duration: 0.55,
        });
      } else {
        this.selectedNominee = false;
        gsap.to(this.$refs.submitBtn, {
          y: "100%",
          duration: 0.1,
        });
        gsap.to(this.$refs.submitInner, {
          opacity: 0,
          duration: 0.2,
        });
        gsap.set(".shimmer", { y: "100%" });
      }
    },
    animateIn() {
      let categoryVideo = this.$refs.categoryVideo;
      categoryVideo.style.webkitFilter = "brightness(65%)";
      gsap.to(this.$refs.categoryTitle, {
        autoAlpha: 1,

        duration: 0.7,
      });
      gsap.to(this.$refs.categoryCtr, {
        autoAlpha: 1,
        duration: 0.7,
      });

      gsap.fromTo(
        this.$refs.progressBar,
        { scaleX: this.previousWidth },
        {
          scaleX: this.currentWidth,
          duration: 0.5,
          delay: 0.15,
          ease: "Power4.out",
        }
      );
    },
    async vote() {
      try {
        await EventService.vote(this.selectedNominee);
        this.trackInteraction({
          interactionName: "Submit Vote",
          widgetType: "CTA Button",
          widgetName: "Submit Vote",
          widgetItemName: "Submit Vote",
          screenName: "Voting Page",
          screenSection: this.category.question.question.default,
        });

        gsap.to([this.$refs.categoryCtr, this.$refs.categoryTitle], {
          autoAlpha: 0,
          duration: 0.25,
          onComplete: this.next.bind(this),
        });
      } catch (err) {
        if (err.response.status === 429) {
          // show error screen? or just pretend the vote was counted?
          alert("Uh oh, looks like you voted today. Try again tomorrow!");
        }
        console.log(err.response);
      }
    },
  },
  computed: {
    answers() {
      return this.category.answers;
    },
    questionText() {
      return this.category.question.question.default;
    },
    currentCategory() {
      return this.activeCategoryIdx + 1;
    },
    videoTitle() {
      return categories[this.activeCategoryIdx].key;
    },
    currentWidth() {
      let width = this.activeCategoryIdx / 12;
      return width;
    },
    previousWidth() {
      let width = this.activeCategoryIdx / 12;
      return width - 0.0833;
    },
  },
};
</script>

<style>
.video-shadow {
  box-shadow: 0 0 45px -15px rgba(145, 95, 21, 0.75);
  animation-name: breathe;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
}
@keyframes breathe {
  from {
    box-shadow: 0 0 45px -15px rgba(145, 95, 21, 0.75);
  }
  to {
    box-shadow: 0 0 75px -20px rgba(145, 95, 21, 0.75);
  }
}

.player-icons {
  /* display: none !important; */
  /* opacity: 0 !important; */
}

.play-wrapper {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.shimmer {
  height: 300%;
  background: linear-gradient(
    0deg,
    rgba(170, 115, 48, 0) 0%,
    rgba(180, 115, 23, 0.65) 50%,
    rgba(170, 115, 48, 0) 100%
  );
}
</style>
