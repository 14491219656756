import axios from "axios";

const apiURL = "https://tally.wbdis.co/";
const eventuuid = "aewvote";
const typeuuid = "upvote";

export default {
  async vote(eventQuestionAnsweruuid) {
    const res = await axios({
      method: "GET",
      url: `${apiURL}/v1/vote/event/${eventuuid}/eventQuestionAnswer/${eventQuestionAnsweruuid}/type/${typeuuid}`,
      headers: {
        "x-loadster-user": "1",
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return res;
  },
  async getAllQuestions() {
    let { data } = await axios({
      method: "GET",
      url: `${apiURL}/persistent/eventdetail`,
      params: {
        variables: { args: { eventuuid: "aewvote" } },
      },
      headers: {
        "x-loadster-user1": "1",
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    return data.data.event.eventQuestions;
  },
};
